import { useMutation } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";

import { useAuth0 } from "../../utils/auth0Provider";

import { CreateWebhookVariables } from "../../generated/CreateWebhook";
import { WebhookActionType, WebhookHttpMethod } from "../../generated/globalTypes";
import { UpdateWebhookVariables } from "../../generated/UpdateWebhook";
import CreateWebhookMutation from "../../mutations/CreateWebhookMutation";
import UpdateWebhookMutation from "../../mutations/UpdateWebhookMutation";

import ConfirmationAlert from "../ConfirmationAlert";
import InputUserSearch from "../InputUserSearch";

const urlRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()@:%_+.~#?&//={}[\]]*)$/;

export interface IWebhookFormProps {
  offerId: number;
  onClose: () => void;
  onExited: () => void;
  open: boolean;
  type: "Create" | "Update";
  updateWebhookActionType?: WebhookActionType;
  updateWebhookHttpBody?: string | null;
  updateWebhookHttpMethod?: WebhookHttpMethod;
  updateWebhookId?: string;
  updateWebhookUrl?: string;
  updateWebhookUser?: {
    id: string;
    displayId: number | null;
    name: string;
  };
}

const WebhookForm = ({
  offerId,
  onClose,
  onExited,
  open,
  type,
  updateWebhookActionType,
  updateWebhookHttpBody,
  updateWebhookHttpMethod,
  updateWebhookId,
  updateWebhookUrl,
  updateWebhookUser
}: IWebhookFormProps) => {
  const emptyStringValue = undefined;

  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const assignWebhookAffiliate = hasPermission("manage:webhooks:all");

  const [dirty, setDirty] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [userIdState, setUserIdState] = useState(updateWebhookUser?.id);
  const [webhookActionType, setWebhookActionType] = useState(
    updateWebhookActionType
  );
  const [webhookHttpBody, setWebhookHttpBody] = useState(updateWebhookHttpBody);
  const [webhookHttpMethod, setWebhookHttpMethod] = useState(updateWebhookHttpMethod || WebhookHttpMethod.GET);
  const [webhookUrl, setWebhookUrl] = useState(updateWebhookUrl);
  const webhookUrlInvalid = !!webhookUrl && !urlRegex.test(webhookUrl)

  const [upsertWebhook, { loading }] = useMutation(
    type === "Create" ? CreateWebhookMutation : UpdateWebhookMutation
  );

  useEffect(() => {
    setWebhookActionType(updateWebhookActionType);
    setWebhookHttpBody(updateWebhookHttpBody);
    setWebhookHttpMethod(updateWebhookHttpMethod || WebhookHttpMethod.GET);
    setWebhookUrl(updateWebhookUrl);
  }, [updateWebhookActionType, updateWebhookHttpBody, updateWebhookHttpMethod, updateWebhookUrl]);

  const setInputState = (
    setFunction: ((value: any) => void)
  ) => (
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<{
          name?: string | undefined;
          value: unknown;
        }>
  ) => {
    setDirty(true);
    setFunction(event.target.value);
  };

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const onCloseReset = () => {
    setDirty(false);
    setWebhookActionType(emptyStringValue);
    setWebhookHttpBody(emptyStringValue);
    setWebhookHttpMethod(WebhookHttpMethod.GET);
    setWebhookUrl(emptyStringValue);
    onClose();
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (webhookUrlInvalid) return;

    const webhookPostPut = webhookHttpMethod && [WebhookHttpMethod.POST, WebhookHttpMethod.PUT].includes(webhookHttpMethod);
    let variables: CreateWebhookVariables | UpdateWebhookVariables;

    if (type === "Create") {
      variables = {
        actionType: webhookActionType,
        httpBody: webhookPostPut ? webhookHttpBody : undefined,
        httpMethod: webhookHttpMethod,
        offerId,
        url: webhookUrl!,
        userId: assignWebhookAffiliate ? userIdState : undefined
      } as CreateWebhookVariables;
    } else {
      variables = {
        actionType: webhookActionType,
        httpBody: webhookPostPut ? webhookHttpBody : null,
        httpMethod: webhookHttpMethod,
        url: webhookUrl,
        webhookId: updateWebhookId!
      } as UpdateWebhookVariables;
    }

    upsertWebhook({
      awaitRefetchQueries: true,
      refetchQueries: ["Webhooks"],
      variables
    }).then(() => onCloseReset());
  };

  return (
    <>
      <Dialog onClose={handleClose} onExited={onExited} open={open}>
        <form onSubmit={onSubmit}>
          <DialogTitle>{`${type} Pixel`}</DialogTitle>

          <DialogContent>
            <div className={classes.formInput}>
              <Typography>
                To pass back subids, use %s1%, %s2%, %s3%, %s4%, or %s5%
                placeholders.
              </Typography>
              <Typography>
                To pass back the deposit amount, use %deposit_amount% -- Note
                that not all casinos provide us with the deposit amount.
              </Typography>
            </div>

            {assignWebhookAffiliate && (
              <InputUserSearch
                className={classes.formInput}
                onSelect={setUserIdState}
                staticUser={updateWebhookUser}
              />
            )}

            <FormControl className={classes.formInput} fullWidth>
              <InputLabel id="actionTypeLabel">Event</InputLabel>
              <Select
                labelId="actionTypeLabel"
                id="actionTypeSelect"
                onChange={setInputState(setWebhookActionType)}
                required
                value={webhookActionType || ""}
              >
                {Object.values(WebhookActionType).map(actionType => (
                  <MenuItem key={actionType} value={actionType}>
                    {actionType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formInput} fullWidth>
              <InputLabel id="httpMethodLabel">HTTP Method</InputLabel>
              <Select
                labelId="httpMethodLabel"
                id="httpMethodSelect"
                onChange={setInputState(setWebhookHttpMethod)}
                required
                value={webhookHttpMethod}
              >
                {Object.keys(WebhookHttpMethod).map(httpMethod => (
                  <MenuItem key={httpMethod} value={httpMethod}>
                    {httpMethod}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              className={classes.formInput}
              fullWidth
              id="webhookUrl"
              error={webhookUrlInvalid}
              helperText={webhookUrlInvalid ? "Invalid URL" : undefined}
              label="URL"
              onChange={setInputState(setWebhookUrl)}
              required
              value={webhookUrl || ""}
            />

            {webhookHttpMethod && [WebhookHttpMethod.POST, WebhookHttpMethod.PUT].includes(webhookHttpMethod) && (
              <TextField
                className={classes.formInput}
                fullWidth
                id="webhookHttpBody"
                label="HTTP Body"
                multiline
                onChange={setInputState(setWebhookHttpBody)}
                required
                minRows={6}
                value={webhookHttpBody || ""}
              />
            )}
          </DialogContent>

          <DialogActions>
            <Button disabled={loading || !open} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={loading || !open}
              type="submit"
              variant="contained"
            >
              {type}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onCloseReset();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formInput: { marginBottom: spacing(2) }
}));

export default WebhookForm;
